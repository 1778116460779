@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

#login-body {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    background-color: rgb(231, 231, 231);
}

.container {
    width: 500px;
    background-color: white;
    box-shadow: 8px 8px 20px rgb(128, 128, 128);
    position: relative;
    overflow: hidden;
}

.btn-lol {
    height: 60px;
    width: 300px;
    margin: 20px auto;
    box-shadow: 10px 10px 30px rgb(163, 164, 255);
    border-radius: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.login,
.signup {
    font-size: 22px;
    border: none;
    outline: none;
    background-color: transparent;
    position: relative;
    cursor: pointer;
}

.slider {
    height: 60px;
    width: 150px;
    border-radius: 50px;
    background-image: linear-gradient(to right,
            rgb(110, 112, 255),
            rgb(0, 4, 255));
    position: absolute;
    top: 20px;
    left: 100px;
    transition: all 0.5s ease-in-out;
}

.moveslider {
    left: 250px;
}

.form-section {
    width: 1000px;
    padding: 20px 0;
    display: flex;
    position: relative;
    transition: all 0.5s ease-in-out;
    left: 0px;
}

.form-section-move {
    left: -500px;
}

.login-box,
.signup-box {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 40px;
}

.login-box {
    gap: 5px;
}

.signup-box {
    gap: 3px;
}

.ele {
    height: 45px;
    width: 400px;
    outline: none;
    border: none;
    border: 1px solid rgb(133 133 133);
    border-radius: 50px;
    padding-left: 30px;
    font-size: 18px;
}

.clkbtn {
    height: 50px;
    width: 150px;
    border-radius: 50px;
    background-image: linear-gradient(to right,
            rgb(110, 112, 255),
            rgb(0, 4, 255));
    font-size: 22px;
    border: none;
    cursor: pointer;
}

/* For Responsiveness of the page */

@media screen and (max-width: 650px) {
    .container {
        height: 600px;
        width: 300px;
    }

    .title {
        font-size: 15px;
    }

    .btn-lol {
        height: 50px;
        width: 200px;
        margin: 20px auto;
    }

    .login,
    .signup {
        font-size: 19px;
    }

    .slider {
        height: 50px;
        width: 100px;
        left: 50px;
    }

    .moveslider {
        left: 150px;
    }

    .form-section {
        height: 500px;
        width: 600px;
    }

    .form-section-move {
        left: -300px;
    }

    .login-box,
    .signup-box {
        height: 100%;
        width: 300px;
    }

    .ele {
        height: 50px;
        width: 250px;
        font-size: 15px;
    }

    .clkbtn {
        height: 50px;
        width: 130px;
        font-size: 19px;
    }
}

@media screen and (max-width: 320px) {
    .container {
        height: 600px;
        width: 250px;
    }

    .heading {
        font-size: 30px;
    }

    .title {
        font-size: 10px;
    }

    .btn-lol {
        height: 50px;
        width: 200px;
        margin: 20px auto;
    }

    .login,
    .signup {
        font-size: 19px;
    }

    .slider {
        height: 50px;
        width: 100px;
        left: 27px;
    }

    .moveslider {
        left: 127px;
    }

    .form-section {
        height: 500px;
        width: 500px;
    }

    .form-section-move {
        left: -250px;
    }

    .login-box,
    .signup-box {
        height: 100%;
        width: 250px;
    }

    .ele {
        height: 50px;
        width: 220px;
        font-size: 15px;
    }

    .clkbtn {
        height: 50px;
        width: 130px;
        font-size: 19px;
    }
}

.alert-danger {
    color: #78261f;
    background-color: #fadbd8;
    border-color: #f8ccc8;
}

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.35rem;
}